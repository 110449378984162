/**
 * Получение существующего поля из объекта или всего объекта.
 * @param object Объект для получения данных
 * @param field Имя поля (если не передано - возвращается весь объект)
 * @param defaultValue Значение, возвращаемое при отсутствии поля или объекта
 * @returns Возвращается значение поля, объект или null
 */
export function getField (object, field, defaultValue = null) {
  return object instanceof Object
    ? field
      ? object[field]
        ? object[field]
        : defaultValue
      : object
    : defaultValue
}
