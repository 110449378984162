<template>
  <div class="loading-block d-flex flex-column justify-content-center align-items-center text-center">
    <loader class="pb-3" />
    <slot>Данные загружаются, пожалуйста <br /> подождите</slot>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'

export default {
  name: 'LoadingBlock',
  components: {
    Loader
  }
}
</script>

<style lang="scss" scoped>
.loading-block {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: .2px;
  font-weight: 700;
  min-height: 385px;
}
</style>
