/**
 * Проверка является ли входной параметр объектом
 * @param object
 * @returns {Boolean}
 */
export function isObject (object) {
  return typeof object === 'object' && object !== null
}
/**
 * Проверка является ли объект пустым
 * @param object
 * @returns {Boolean}
 */
export function isObjectEmpty (object) {
  return !Object.keys(object).length
}
