<template>
  <div
      class="loading-block__loader d-flex"
      :class="{ 'loading-block__loader-fix': !inText }"
  >
    <div class="loading-block__loader-item">
      <svg width="100%" height="100%" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.5" cy="8.5" r="7.5" fill="#FFCC00" stroke="white" stroke-width="2"></circle>
      </svg>
    </div>
    <div class="loading-block__loader-item">
      <svg width="100%" height="100%" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.5" cy="8.5" r="7.5" fill="#FFCC00" stroke="white" stroke-width="2"></circle>
      </svg>
    </div>
    <div class="loading-block__loader-item">
      <svg width="100%" height="100%" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.5" cy="8.5" r="7.5" fill="#FFCC00" stroke="white" stroke-width="2"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    inText: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-block__loader {
  position: relative;
  padding-bottom: 0;

  &-fix {
    top: -3px;
  }
  &-item {
    width: 17px;
    height: 17px;
    position: relative;
    animation-name: ball-jump;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    &:first-child {
      left: 5px;
      animation-delay: 0ms;
    }
    &:nth-child(2) {
      animation-delay: 500ms;
    }
    &:last-child {
      left: -5px;
      animation-delay: 1000ms;
    }
  }
}
</style>
