<template>
  <page-wrapper>
    <template v-slot:before_content>
      <user-info class="pt-5" />
    </template>
    <template v-slot:content>
      <h2 class="h2 h2_bold mb-1">Личные данные</h2>
      <p class="light mb-4">Представьтесь и оставьте свои контакты. чтобы мы могли связаться с вами.</p>
      <loading-block v-if="loading" />
      <form-observer
        v-else
        :validation-schema="editUserSchema"
        v-slot="{ resetForm }"
        @submit="onSubmit"
      >
        <div class="row">
          <div class="col-12 col-lg-6 pb-4 pb-lg-0">
            <input-text
              name="lastname"
              label-text="Фамилия"
              placeholder="Введите фамилию"
              :value="getField(localState, 'lastname', '')"
            />
          </div>
          <div class="col-12 col-lg-6 pb-4 pb-lg-0">
            <input-text
              name="firstname"
              label-text="Имя"
              placeholder="Введите имя"
              :value="getField(localState, 'firstname', '')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 pb-4 pb-lg-0">
            <input-text
              name="middlename"
              label-text="Отчество"
              placeholder="Введите отчество"
              :value="getField(localState, 'middlename', '')"
            />
          </div>
          <div class="col-12 col-lg-6 pb-4 pb-lg-0">
            <input-phone
              ref="inputPhone"
              name="phone"
              label-text="Телефон"
              placeholder="Введите телефон"
              :default-phone="getField(localState, 'phone', '')"
              @updateMask="updateMask"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 pb-4 pb-lg-0">
            <input-password
              name="password"
              label-text="Новый пароль"
              :is-show-password="true"
              placeholder="Введите пароль"
            />
          </div>
          <div class="col-12 col-lg-6 pb-4 pb-lg-0">
            <input-password
              name="password_repeat"
              label-text="Повторите пароль"
              :is-show-password="true"
              placeholder="Повторите пароль"
            />
          </div>
        </div>
        <div class="save-changes">
          <div class="container-outer container-outer_width-720">
            <div class="save-changes__body">
              <a
                href="javascript:void(0);"
                class="save-changes__clear"
                @click="resetForm"
              >
                <svg class="icon icon-clear"><use xlink:href="#icon-clear"></use></svg>
                <span>
                 Отменить изменения
              </span>
              </a>
              <loader v-if="sendingRequest" />
              <button class="button button_size-64 button_no-shadow button_no-transform save-changes__button">
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </form-observer>
    </template>
  </page-wrapper>
</template>

<script>
import PageWrapper from '@/components/common/PageWrapper'
import UserInfo from '@/components/common/UserInfo'
import Loader from '@/components/common/Loader'
import LoadingBlock from '@/components/common/LoadingBlock'
import { Form as FormObserver } from 'vee-validate'
import InputText from '@/components/common/InputText'
import InputPhone from '@/components/common/InputPhone'
import InputPassword from '@/components/common/InputPassword'
import { setEditUserSchema } from '@/validation'
import { mapState, mapActions } from 'vuex'
import { getField } from '@/assets/helpers/get_field'
import { isObjectEmpty } from '@/assets/helpers/object_helpers'

export default {
  name: 'Settings',
  components: {
    PageWrapper,
    UserInfo,
    Loader,
    LoadingBlock,
    FormObserver,
    InputText,
    InputPhone,
    InputPassword
  },
  data () {
    const editUserSchema = setEditUserSchema(this)
    return {
      editUserSchema,
      loading: false,
      sendingRequest: false,
      mask: null,
      localState: null
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  beforeMount () {
    this.loading = true
    this.$axios.get(this.getPathRequest('user_info'))
      .then(response => {
        if (this.user !== response.data) this.setUser(response.data)
      })
      .catch(error => {
        this.openModal('ErrorModal', {
          title: 'Ошибка получения данных пользователя',
          text: error.response.data.message
            ? error.response.data.message
            : 'Во время получения данных пользователя произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
        })
      })
      .finally(() => {
        this.localState = Object.assign({}, this.user)
        this.loading = false
      })
  },
  methods: {
    ...mapActions([
      'setUser'
    ]),
    onSubmit (values) {
      const sourceData = Object.assign({}, values)
      const requestData = {}
      if (sourceData.phone) {
        sourceData.phone = this.$refs.inputPhone.fullValue
      }
      Object.keys(sourceData).forEach(item => {
        if (Object.prototype.hasOwnProperty.call(this.localState, item) && this.localState[item] !== sourceData[item]) {
          requestData[item] = sourceData[item]
        }
      })
      if (sourceData.password) {
        requestData.password = sourceData.password
      }
      if (!isObjectEmpty(requestData)) {
        this.sendingRequest = true
        this.$axios.post(this.getPathRequest('user_update'), requestData)
          .then(response => {
            this.setUser(response.data)
            this.localState = Object.assign({}, response.data)
            this.openModal('SuccessModal', {
              title: 'Данные обновлены',
              text: 'Личные данные пользователя былы успешно обновлены.'
            })
          })
          .catch(error => {
            this.openModal('ErrorModal', {
              title: 'Ошибка обновления данных',
              text: error.response.data.message
                ? error.response.data.message
                : 'Во время обновления данных произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
            })
          })
          .finally(() => {
            this.sendingRequest = false
          })
      }
    },
    updateMask (value) {
      this.mask = value.mask
    },
    getField
  }
}
</script>
